import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

export default function DashboardMember({ member }) {
  const additionalData = useRef();
  const navigate = useNavigate();

  const goOnMemberHandler = id => {
    navigate('/member/' + id);
  };

  return (
    <>
      <tr>
        <th
          onClick={() => goOnMemberHandler(member._id)}
          className="collapse-title border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left"
        >
          {member._id}
        </th>
        <th className="collapse-title border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
          {member.email}
        </th>
        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
          <i className="fas fa-circle fa-sm text-green-500 mr-2"></i> Online
        </th>
        <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-right">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
            onClick={() => additionalData.current.classList.toggle('hidden')}
          >
            See more
          </button>
        </th>
      </tr>
      <tr ref={additionalData} className="hidden">
        <td colSpan={12}>
          <table className="bg-gray-200 items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Domain
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Current page
                </th>
                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  Platform
                </th>

                <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                  User agent
                </th>
              </tr>
            </thead>
            <tbody>
              {member.sockets.map((socket, i) => (
                <tr key={i}>
                  <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {socket.domain}
                  </th>
                  <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {socket.pathname}
                  </th>
                  <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {socket.platform}
                  </th>
                  <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                    {socket.userAgent}
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </td>
      </tr>
    </>
  );
}
