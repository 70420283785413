import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import io from 'socket.io-client';

import StatusCard from 'components/StatusCard';
import DasboardUsers from './DashboardMemberList';

export default function Dashboard() {
  const [socket, setSocket] = useState(null);
  const [members, setMembers] = useState([]);

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const newSocket = io(`/socket/admin/online`, {
      extraHeaders: {
        Authorization: 'Bearer ' + user?.token,
      },
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, [user]);

  const findUniqueDomain = domainsArr => {
    return String([...new Set(domainsArr.map(el => el.domain))].length);
  };

  useEffect(() => {
    socket?.on('getUsers', mems => {
      setMembers(mems);
      // console.log(mems);
    });
  }, [socket]);

  return (
    <>
      <div className="bg-light-blue-500 pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2">
            <StatusCard
              color="pink"
              icon="groups"
              title="Online users"
              amount={String(members.length)}
              percentage="1"
              percentageIcon="arrow_upward"
              percentageColor="green"
              date="Second ago"
            />

            <StatusCard
              color="purple"
              icon="poll"
              title="Active domains"
              amount={findUniqueDomain(members)}
              percentage="1"
              percentageIcon="arrow_upward"
              percentageColor="orange"
              date="Second ago"
            />
          </div>
        </div>
      </div>

      <div className="px-3 md:px-8 h-auto -mt-24">
        <div className="container mx-auto max-w-full">
          <div className="grid grid-cols-1 px-4 mb-16">
            <DasboardUsers members={members} />
          </div>
        </div>
      </div>
    </>
  );
}
