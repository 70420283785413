import { Route, Routes, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Elements
import Sidebar from 'components/Sidebar';
import Dashboard from 'modules/dashboard/Dashboard';
import Footer from 'components/Footer';
import { useEffect } from 'react';
import Member from 'pages/Member';

function Admin() {
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return (
    <>
      <Sidebar />
      <div className="md:ml-64">
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/member/:id" element={<Member />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default Admin;
