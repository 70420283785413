import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login, reset } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

// Style components
import Card from '@material-tailwind/react/Card';
import CardHeader from '@material-tailwind/react/CardHeader';
import CardBody from '@material-tailwind/react/CardBody';
import CardFooter from '@material-tailwind/react/CardFooter';
import H5 from '@material-tailwind/react/Heading5';
import InputIcon from '@material-tailwind/react/InputIcon';
import Button from '@material-tailwind/react/Button';

export default function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const { email, password } = formData;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    state => state.auth
  );

  const onSubmit = e => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isSuccess || user) {
      navigate('/');
    }

    dispatch(reset());
  }, [dispatch, isSuccess, navigate, user]);

  return (
    <div className="grid place-items-center h-screen">
      <div className="max-w-sm w-96">
        <form onSubmit={onSubmit}>
          <Card>
            <CardHeader color="lightBlue">
              <H5 color="white" style={{ marginBottom: 0 }}>
                Login
              </H5>
            </CardHeader>

            <CardBody>
              <div className="mb-12 px-4 bg-bb">
                <InputIcon
                  type="email"
                  color="lightBlue"
                  placeholder="Email Address"
                  iconName="email"
                  value={email}
                  name="email"
                  onChange={onChange}
                />
              </div>
              <div className="mb-8 px-4">
                <InputIcon
                  type="password"
                  color="lightBlue"
                  placeholder="Password"
                  iconName="lock"
                  value={password}
                  name="password"
                  autoComplete="true"
                  onChange={onChange}
                />
              </div>
            </CardBody>

            <CardFooter>
              <div className="flex justify-center bg-bb">
                <Button
                  color="lightBlue"
                  buttonType="submit"
                  size="lg"
                  ripple="dark"
                >
                  Submit
                </Button>
              </div>
            </CardFooter>
            {/* Error message */}
            {isError && (
              <div
                className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                role="alert"
              >
                <span className="font-medium">Error!</span> {message}
              </div>
            )}
          </Card>
        </form>
      </div>
    </div>
  );
}
