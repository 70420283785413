import ProfileCard from 'components/ProfileCard';
import { getMemberById } from 'features/members/membersSlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function Member() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { member, isLoading, isError, isSuccess, message } = useSelector(
    state => state.members
  );

  useEffect(() => {
    dispatch(getMemberById(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="pt-14 pb-28 px-3 md:px-8 h-auto">
        <div className="xl:col-start-5 xl:col-end-7 px-4 mb-16 mt-14">
          {member && <ProfileCard memberInfo={member} />}
        </div>
      </div>
    </>
  );
}
