import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Login from 'pages/Login';
import Admin from 'layouts/Admin';

// Tailwind CSS Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/tailwind.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
