import Card from '@material-tailwind/react/Card';
import Image from '@material-tailwind/react/Image';
import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import ProfilePicture from 'assets/img/team-1-800x800.jpg';
import Moment from 'react-moment';

export default function ProfileCard({ memberInfo }) {
  const { member, lastRecord } = memberInfo;
  return (
    <Card>
      <div className="flex flex-wrap justify-center">
        <div className="w-48 px-4 -mt-24">
          <Image src={ProfilePicture} rounded raised />
        </div>
      </div>
      <div className="text-center py-4">
        <H5 color="gray">{member.email}</H5>
        <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Icon name="place" size="xl" />
          {member.country}
        </div>
        <div className="mt-0 mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Icon name="account_circle" size="xl" />
          {member.username}
        </div>
        <div className="mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Icon name="query_builder" size="xl" />
          Last Login:
          <Moment fromNow>{lastRecord.lastLogin}</Moment>
        </div>
        <div className="mb-2 text-gray-700 flex items-center justify-center gap-2">
          <Icon name="pageview" size="xl" />
          Last page visited: " {lastRecord.lastPage} "
        </div>
      </div>
      {/* <CardBody>
        <div className="border-t border-lightBlue-200 text-center px-2 ">
          <LeadText color="blueGray">
            An artist of considerable range, Jenna the name taken by
            Melbourne-raised, Brooklyn-based Nick Murphy writes, performs and
            records all of his own music, giving it a warm, intimate feel with a
            solid groove structure. An artist of considerable range.
          </LeadText>
        </div>
      </CardBody> */}
      {/* <CardFooter>
        <div className="w-full flex justify-center -mt-8">
          <a href="#pablo" className="mt-5" onClick={e => e.preventDefault()}>
            <Button color="purple" buttonType="link" ripple="dark">
              Show more
            </Button>
          </a>
        </div>
      </CardFooter> */}
    </Card>
  );
}
