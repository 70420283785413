import axios from 'axios';

const API_URL = '/api/members';

// Get member by id
const getMemberById = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + `/${id}`, config);
  return response.data;
};

const membersService = {
  getMemberById,
};

export default membersService;
