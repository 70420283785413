import { configureStore } from '@reduxjs/toolkit';

import authReducer from './auth/authSlice';
import membersReducer from './members/membersSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    members: membersReducer,
  },
});
